
import Vue from 'vue'
import ConfirmDialog from './ConfirmDialog.vue'
import { AuthRole } from '@/models/authrole'

export default Vue.extend({
  name: 'FormEditFooter',
  data: () => ({
    isDialog: false,
    isDraftDialog: false,
    showone: false,
    showtwo: false,
    isSaveDialog: false,
    isConfirmed: false,
  }),
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
    isBtnClickable: {
      type: Boolean,
      default: true,
    },
    isSkippingDialog: {
      type: Boolean,
      default: false,
    },
    saveAsDraft: {
      type: Function,
      required: false,
    },
    save: {
      type: Function,
      required: true,
    },
    delete: {
      type: Function,
      required: false,
    },
    cancelFixed: {
      type: Boolean,
      default: false,
    },
    isAllowedToEdit: {
      type: Boolean,
      required: true,
    },
    currentForm: {
      //required: true
    },
  },
  computed: {
    sectionsStatuses() {
      return this.$store.getters['datamanager/getSectionsStatuses']
    },
    //Change the default text only if its not a save/confirm change dialog
    //like this if isSaveDialgo = true, on garde les default value des props. Sinon on met ce quon veut.
    flexProp() {
      if (!this.isSaveDialog) {
        return {
          title: 'confirmdialog.titledelete',
          btnRightLabel: 'basic.btn.delete',
        }
      }
    },
  },
  methods: {
    modify: function() {
      this.$emit('isEditable', true)
      return {}
    },
    cancel: function() {
      this.$emit('isEditable', false)
      this.$emit('hasCanceled')
      return {}
    },
    wantsToSave: function() {
      if (this.currentForm != undefined) {
        let refForm: any = this.currentForm
        if (refForm != undefined && refForm.validate()) {
          this.isSaveDialog = true
          if (!this.isSkippingDialog) {
            this.isDialog = true
            return {}
          } else {
            this.hasConfirmed(true)
          }
        }
      } else {
        this.isSaveDialog = true
        if (!this.isSkippingDialog) {
          this.isDialog = true
          return {}
        } else {
          this.hasConfirmed(true)
        }
      }
    },
    wantsToSaveDraft: function() {
      if (this.currentForm != undefined) {
        this.isSaveDialog = true
        if (!this.isSkippingDialog) {
          this.isDraftDialog = true
          return {}
        } else {
          this.hasConfirmedAsDraft(true)
        }
      } else {
        this.isSaveDialog = true
        this.isDraftDialog = true
        //marche po mais faudrait dequoi du genre
        // this.orgInfos = this.previousData;
        return {}
      }
    },
    wantsToDelete: function() {
      this.isSaveDialog = false
      this.isDialog = true
      //marche po mais faudrait dequoi du genre
      // this.orgInfos = this.previousData;
      return {}
    },

    hasConfirmed(isConfirmed: boolean) {
      if (isConfirmed && this.isSaveDialog) {
        this.save()
      } else if (isConfirmed && !this.isSaveDialog) {
        this.delete()
      }
      this.isDialog = false
    },
    hasConfirmedAsDraft(isConfirmed: boolean) {
      if (isConfirmed && this.isSaveDialog) {
        this.saveAsDraft()
      } else if (isConfirmed && !this.isSaveDialog) {
        this.delete()
      }
      this.isDraftDialog = false
    },
  },

  components: {
    'confirm-dialog': ConfirmDialog,
  },
})
