
import Vue from "vue";
import FormEditFooter from "../../components/UI-Components/FormEditFooter.vue";
import { Snackbar } from "@/models/snackbar";

import i18n from "@/i18n";
import { SaveRes } from "@/models/saveres";

export default Vue.extend({
  data: () => ({
    isEditable: false,
    isFormValid: true,
    hasUnsavedChanges: true,
    roleInOrganization: "",
    phonenumba: "",
    rules: {
      name: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => (v && v.length >= 3) || i18n.t("rules.morethan3char"),
      ],
    },
  }),
  computed: {
    loggedInUserId() {
      return this.$store.getters["auth/getLoggedInUserId"];
    },
    loggedInUserInfos() {
      return this.$store.getters["user/getUserInfos"];
    },
    loggedInUserInfosSaveRes() {
      return this.$store.getters["user/getUserInfosSaveRes"];
    },
  },
  methods: {
    async fetchUserInfos(userId: string) {
      await this.$store.dispatch("user/fetchUserInfos", userId);
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    async saveUserInfos() {
      await this.$store.dispatch("user/saveUserInfos", {
        // firstName: "Alex",
        // lastName: "Test",
        phone: this.phonenumba,
        role: this.roleInOrganization,
        id: this.loggedInUserId,
      });
      return this.loggedInUserInfosSaveRes as SaveRes;
    },
    willSave() {
      this.isEditable = false;
      this.saveUserInfos()
        .then((e) => {
          if (e.status == 200) {
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 422) {
              let snack = {
                isSnacking: true,
                snackColor: "orange",
                snackMessage:
                  this.$t("basic.savewarning").toString() +
                  err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            } else {
              let snack = {
                isSnacking: true,
                snackColor: "error",
                snackMessage:
                  this.$t("basic.saveerror").toString() +
                  err.response.statusText,
                snackTimeout: "2000",
              };
              this.triggerSnack(snack);
            }
          } else console.log(err);
          this.isEditable = true;
        });
    },
  },
  created() {
    if (this.loggedInUserId) this.fetchUserInfos(this.loggedInUserId);
  },
  watch: {
    loggedInUserInfos: {
      handler(res) {
        this.roleInOrganization = res.roleInOrganization;
        this.phonenumba = res.phone;
      },
    },
  },
  components: {
    "form-edit-footer": FormEditFooter,
  },
});
